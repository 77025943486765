define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat
      this.containerClassNamesString
      " "
      this.attachmentClass
      " "
      this.containerClass
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "68HsLkvZ",
    "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"containerClassNamesString\"]],\" \",[30,0,[\"attachmentClass\"]],\" \",[30,0,[\"containerClass\"]]],null]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/in-place-dialog.hbs",
    "isStrictMode": false
  });
  class InPlaceDialog extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", '');
    }
    get containerClassNamesString() {
      var _this$containerClassN, _this$containerClassN2;
      const addonClassNamesString = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place'].join(' ');
      const containerClassNamesString = ((_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 ? void 0 : _this$containerClassN.join) && ((_this$containerClassN2 = this.containerClassNames) === null || _this$containerClassN2 === void 0 ? void 0 : _this$containerClassN2.join(' ')) || this.containerClassNames || '';
      return `${addonClassNamesString} ${containerClassNamesString}`;
    }
  }
  _exports.default = InPlaceDialog;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InPlaceDialog);
});